import adminNav from './admin-navigation';
import dealerNav from './dealer-navigation';
import warrantyNav from './warranty-navigation';
import customerNav from './customer-navigation';
import router from '@/router/index'
import {viewAccess, viewPermission,sleep} from '@/utils/utils';
import store from '@/store';
import { BootstrapVue, IconsPlugin, ModalPlugin } from 'bootstrap-vue';

let getPerm = router.app.$session.get('permission')
let getExpire= router.app.$session.get('expireTime')
if(!getPerm){
  router.push(`/pages/login`)
}
let permission
async function delayPerm(ms) {
  await sleep(1500)
  let Parse = JSON.parse(getPerm)
  return Parse
}

permission = delayPerm()
//console.info('delay Perm',permission)
var navMenu = []


var menu = [
  ...adminNav,
  ...dealerNav,
  ...warrantyNav,
  ...customerNav,
]

var permissionError = function (module =''){
    
    alert('Your session has expired, please login again');

    var logData= (router.app.$session.get('userID'))?
    {
      id:router.app.$session.get('userID'),
      message:"Logged Out Automatically"
    }:{
      email:"noname@rsp.com",
      message:"[USER ID NOT FOUND] - Logged Out Automatically",
      name:"noname"
    }

    store.dispatch('log/addLog',logData).then(res => {
      //console.error('permission for user data on module '+module+' has expired- please login again')
    
    
      router.app.$session.clear()
      router.app.$session.destroy()

    window.location.reload()
    //console.info('reload triggered')
    })

    
}
var now = new Date();
var nowSecond =now.setSeconds(now.getSeconds());

//console.info('session expires in '+((getExpire-nowSecond)/1000)+" seconds")
if(!getExpire){
  
  permissionError('expiration')
}

if((getExpire-nowSecond)<0){
  permissionError('expiration')
}

//console.info({permission})
if(permission.allAccess) {
  navMenu = [...menu]
} else {
  
  /** FOR REFERENCE PURPOSE ONLY */
  // let userAccess_masterdata = permission.masterdata_menu.filter( el => {
  //   return el.view;
  // }).map(el => {
  //   return el.name;
  // })
  // let userAccess_masterdata = viewAccess('masterdata_menu')
  // //console.info({userAccess_masterdata})

  
  // let findMD = menu.find(el => {return el.title === 'Master Data'})
  // let childMasterData = []
  // let masterdataItems = {}
  // //console.info(findMD)
  // if (findMD !== undefined) {
  //   for (let el of findMD.children) {
  //     if(userAccess_masterdata.includes(el.name.replace(/\s+/g, '-').toLowerCase())) {
  //       childMasterData.push(el)
  //     }
  //   }
  //   masterdataItems = {
  //     title: findMD.title,
  //     icon: 'UsersIcon',
  //     children: childMasterData
  //   }
  // }
  /** END OF REFERENCE PURPOSE */

  //Filter menu which user can view

  //Master Data
  let userAccess_masterdata = viewAccess('masterdata_menu');
  
  //Check view access, if returns false, nav 
  if(!userAccess_masterdata){
    console.info('userAccess master data get permission returns error');
    permissionError('masterdata')
  }
  
  //console.info({userAccess_masterdata})
  viewPermission(menu,'Master Data',navMenu,userAccess_masterdata)

  //Dashboard
  let userAccess_dashboard = viewAccess('dashboard_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_dashboard){
    console.info('userAccess dashboard get permission returns error');
    permissionError('userAccess_dashboard')
  }

  viewPermission(menu,'Dashboard',navMenu,userAccess_dashboard)


  //Order
  let userAccess_order = viewAccess('order_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_order){
    //console.info('userAccess order get permission returns error');
    permissionError('order')
  }

  

  //console.info({userAccess_order})
  viewPermission(menu,'Purchase Order',navMenu,userAccess_order)

  //Sales Order
  let userAccess_sales = viewAccess('sales_order_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_sales){
    console.info('userAccess order get permission returns error');
    permissionError('sales')
  }

  //console.info({userAccess_sales})
  viewPermission(menu,'Sales Order',navMenu,userAccess_sales)
  

  //Inventory
  let userAccess_inventory = viewAccess('inventory_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_inventory){
    console.info('userAccess inventory get permission returns error');
    permissionError('inventory')
  }


  //console.info({userAccess_inventory})
  viewPermission(menu,'Inventory',navMenu,userAccess_inventory)
  

  //Delivery
  let userAccess_delivery = viewAccess('delivery_order_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_delivery){
    console.info('userAccess delivery get permission returns error');
    permissionError('delivery')
  }


  //console.info({userAccess_delivery})
  viewPermission(menu,'Delivery Orders',navMenu,userAccess_delivery)

  //Shipping
  let userAccess_shipping = viewAccess('shipping_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_shipping){
    console.info('userAccess shipping get permission returns error');
    permissionError('shipping')
  }
  
  //console.info({userAccess_shipping})
  viewPermission(menu,'Shipping',navMenu,userAccess_shipping)

  
  //Self Collect
  let userAccess_self_collect = viewAccess('self_collect_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_self_collect){
    console.info('userAccess self collect get permission returns error');
    permissionError('self collect')
  }

  //console.info({userAccess_self_collect})
  viewPermission(menu,'Self Collect',navMenu,userAccess_self_collect)


  //Return Delivery
  let userAccess_return = viewAccess('return_delivery_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_return){
    console.info('userAccess return delivery get permission returns error');
    permissionError('return delivery')
  }
   
  //console.info({userAccess_return})
  viewPermission(menu,'Return Delivery',navMenu,userAccess_return)
  viewPermission(menu,'Manual Return',navMenu,userAccess_return)


  //Redelivery
  /**
   * redelivery temp, hardcoded
   */
  let userAccess_redelivery= viewAccess('redelivery_menu')
  
  //uncomment this if user rule is established
  //let userAccess_redelivery = viewAccess('delivery_order_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_redelivery){
    console.info('userAccess redelivery delivery get permission returns error');
    permissionError('redelivery')
  }
   
  //console.info({userAccess_redelivery})
  viewPermission(menu,'Redelivery',navMenu,userAccess_redelivery)
  
  //Warranty
  let userAccess_warranty = viewAccess('warranty_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_warranty){
    console.info('userAccess self collect get permission returns error');
    permissionError('warranty')
  }


  //console.info({userAccess_warranty})
  viewPermission(menu,'Warranty',navMenu,userAccess_warranty)
  

  //Promo
  let userAccess_warranty_master = viewAccess('warranty_master_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_warranty_master){
    console.info('userAccess warranty master get permission returns error');
    permissionError('warranty master')
  }


  //console.info({userAccess_warranty_master})
  viewPermission(menu,'Warranty Master',navMenu,userAccess_warranty_master)


  //Report
  let userAccess_report = viewAccess('report_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_report){
    console.info('userAccess report get permission returns error');
    permissionError('report')
  }


  //console.info({userAccess_report})
  viewPermission(menu,'Report',navMenu,userAccess_report)

  //Settings
  let userAccess_settings = viewAccess('settings_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_settings){
    console.info('userAccess settings get permission returns error');
    permissionError('settings')
  }


  //console.info({userAccess_settings})
  viewPermission(menu,'Settings',navMenu,userAccess_settings)

  //Resend to SAP
  let userAccess_resend = viewAccess('retrigger_api_menu')

  //Check view access, if returns false, nav 
  if(!userAccess_resend){
    console.info('userAccess retrigger get permission returns error');
    permissionError('userAccess_resend')
  }
   
  //console.info({userAccess_resend})
  viewPermission(menu,'Retrigger API',navMenu,userAccess_resend)

  //console.info({navMenu})

  //Manual Create
  let userAccess_manualcreate = viewAccess('manualcreate_menu')
  //Check view access, if returns false, nav 
  if(!userAccess_manualcreate){
    console.info('userAccess manual create get permission returns error');
    permissionError('manualcreate')
  }


  //console.info({userAccess_delivery})
  viewPermission(menu,'Manual Create',navMenu,userAccess_manualcreate)
    
  // //Mass DO Update
  // let userAccess_massdoupdate = viewAccess('massdoupdate_menu')
  // //Check view access, if returns false, nav 
  // if(!userAccess_massdoupdate){
  //   console.info('userAccess mass do update get permission returns error');
  //   permissionError('massdoupdate')
  // }


  // //console.info({userAccess_delivery})
  // viewPermission(menu,'Mass DO Update',navMenu,userAccess_massdoupdate)
  
  //Mass DO Update
  let userAccess_utility = viewAccess('utilitytools_menu')
  //Check view access, if returns false, nav 
  if(!userAccess_utility){
    console.info('userAccess utility tools get permission returns error');
    permissionError('utilitytools')
  }


  //console.info({userAccess_delivery})
  viewPermission(menu,'Utility Tools',navMenu,userAccess_utility)

  //Error, used to test permission get error handling
  /*
  let user_error = viewAccess('user_error')

  //Check view access, if returns false, nav 
  if(!user_error){
    console.info('userAccess user error get permission returns error');
    permissionError('error')
  }
  */
  
  
}

export default navMenu